/*********************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance    *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://www.apache.org/licenses/LICENSE-2.0                                                                    *
 *                                                                                                                    *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 *********************************************************************************************************************/
// eslint-disable-next-line
export default {
  Auth: {
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: "us-west-2_V3NQHveSq",
    userPoolWebClientId: "3o9f54bg4ri682foe79d19udsn"
  },
  API: {
    endpoints: [
      {
        name: 'CDF_AUTO',
        endpoint: 'https://api.zevx.com',
        //endpoint: 'http://127.0.0.1:8080',
        //endpoint: 'https://dev-api.zevx.com',
        custom_header: async () => {
          return localStorage.getItem('signedIn') === "true" ? {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          } : { };
        }
      },
      {
        name: 'MAPBOX',
        endpoint: "https://api.mapbox.com"
      },
      {
        name: 'GEOCODIO',
        endpoint: "https://api.geocod.io"
      },
      {
	name: 'LIVE',
	endpoint: "https://vcg-data.zevx.com",
	//endpoint: "http://127.0.0.1:8000",
        custom_header: async () => {
          return { "X-API-Key": '9b9ad481e570435f8d521005665ec9b9' };
        }
      }
    ]
  }
};
